@import 'index';

*::selection {
  background: $bg-CTA-primary;
}

body {
  margin: 0;
  padding: 0;
  height: 100vh;
  font-family: 'Mulish', sans-serif;
}

body::-webkit-scrollbar {
  -webkit-appearance: none;
}

body::-webkit-scrollbar:vertical {
  width: 10px;
  height: 6px;
}

body::-webkit-scrollbar-button:increment,
body::-webkit-scrollbar-button {
  display: none;
}

body::-webkit-scrollbar:horizontal {
  width: 10px;
  height: 6px;
}

body::-webkit-scrollbar-thumb {
  background-color: #89d3b8;
  border-radius: 4px;
}

body::-webkit-scrollbar-track {
  border-radius: 4px;
}

// loader styles init

.loader-secondary {
  width: 48px;
  height: 48px;
  border-width: 3px;
  border-style: dashed solid solid dotted;
  border-color: #004949 #004949 transparent #004949;
  border-radius: 50%;
  display: inline-block;
  position: relative;
  box-sizing: border-box;
  animation: rotation 1s linear infinite;
}
.loader-secondary::after {
  content: '';
  box-sizing: border-box;
  position: absolute;
  left: 20px;
  top: 31px;
  border: 10px solid transparent;
  border-right-color: #004949;
  transform: rotate(-40deg);
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

// loader styles finish

.suspense {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100%;
}

.fixed-nav {
  display: flex;
  position: fixed !important;
  z-index: 1000;
  top: 0;
  width: 100%;
}

.btn-see-talents {
  width: 20%;
  margin: auto;
}

.arrow-style {
  transition: 0.7s ease;
}

.arrow-style:hover {
  background-color: #23afaf;
}

.input-wrapper {
  position: relative;
}

.input-icon {
  color: #6b8282;
  position: absolute;
  width: 20px;
  height: 20px;
  left: 12px;
  top: 50%;
  transform: translateY(-50%);
}

.input-icon-login {
  color: #6b8282;
  position: absolute;
  width: 20px;
  height: 20px;
  right: 0;
}

.input-password {
  padding: 15px 35px 15px 15px;
}

.input-icon.password {
  left: unset;
  right: 12px;
  cursor: pointer;
}

.margin-global {
  margin-bottom: 30%;
}

.verification-code-container {
  transition: 5s ease;
  transition: 5s cubic-bezier(0.075, 0.82, 0.165, 1);
}

.transition {
  transition: width 0.5s, height 2s;
}

.animation {
  animation-name: example;
  animation-duration: 2s;
  animation-iteration-count: initial;
  animation-direction: alternate;
}

.custom-bg {
  background: rgba(0, 0, 0, 0.3);
  height: 100%;
}

.custom-font {
  font-size: 65px;
}

.custom-font-two {
  font-size: 30px;
}

.custom-font-three {
  font-size: 25px;
}

.item-hover {
  border-color: #4adede;
  transition: 0.8s ease;
}

.item-hover:hover {
  background-color: #4adede;
}

.start-styles {
  padding-top: 5%;
}

.header-button {
  color: white;
}

.header-button:hover {
  color: #4adede;
  transition: 0.8s ease;
}

.faq-style {
  cursor: pointer;
}

.img-hover:hover {
  opacity: 0.6;
  transition: 0.6s ease;
}

.card-hover {
  cursor: pointer;
}

.custom-gap {
  gap: 70%;
}

.card-hover:hover {
  border: 1px solid #2c3237 !important;
  transition: 0.6s ease;
}

.heart-styles {
  color: #2c3237;
  transition: 0.8s ease;
  cursor: pointer;
}

.heart-styles:hover {
  color: #ff3040;
}

.star-styles {
  color: #2c3237;
  transition: 0.8s ease;
  cursor: pointer;
}

.add-star-style {
  color: rgb(212, 212, 14);
  transition: 0.8s ease;
  cursor: pointer;
}

.star-styles:hover {
  color: rgb(212, 212, 14);
}

.heart-styles-two {
  color: gray;
  transition: 0.8s ease;
  cursor: pointer;
}

.heart-styles-two:hover {
  color: #ff3040;
}

.add-favorite {
  color: #ff3040;
}

.zoom {
  box-shadow: 0px 2px 4px 0px #00212113;
}

.zoom:hover {
  box-shadow: 0px 8px 16px 0px #2b115926;
  transition: 0.5s ease;
}

.talent-state-off {
  background-color: #ff3040;
  width: 15px;
  height: 15px;
}

.talent-state-on {
  background-color: #31a24c;
  width: 15px;
  height: 15px;
}

.font-small {
  font-size: 12px;
}

.font-small-benefits {
  font-size: 16px;
}

.loader-styles {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 25%;
  margin-bottom: 25%;
}

.brown {
  background-color: #934f28;
}

.cursor-pointer {
  cursor: pointer;
}

.add-effect {
  animation-name: add;
  animation-duration: 0.5s;
  animation-direction: initial;
}

.process-card-title {
  font-size: 12px;
}

.process-card-subtitle {
  font-size: 11px !important;
}

.process-card-skills-title {
  font-size: 9px !important;
}

.process-table-style {
  width: 25%;
}

.background-color-custom {
  background-color: #23afaf;
}

.custom-h5 {
  margin: 0 !important;
  padding: 0 !important;
}

.color-primary-custom {
  color: #23afaf;
}

.notification-styles {
  right: 0;
  margin-top: 2%;
  margin-right: 80px;
  width: 424px;
  z-index: 3000;
  overflow: auto;
}

.notification-container-styles {
  position: fixed;
  background: rgba(250, 250, 250, 0.7);
  backdrop-filter: blur(3.5px);
  height: 100vh;
  width: 100%;
}

.number-notifications-style {
  margin-top: -10px;
}

.responsive-width {
  width: 25%;
}

.responsive-small-width {
  width: 50px;
}

.table td {
  padding: 0.75rem;
}

.table th {
  padding: 0.75rem;
}

.align-items-offer-card-responsive {
  align-items: end;
}

.sumary-styles {
  width: 100%;
  height: auto;
  word-wrap: break-word;
  overflow: auto;
}

.sumary-styles-private-resume {
  width: 100%;
  height: auto;
  word-wrap: break-word;
  overflow: auto;
}

.my-resume-container {
  max-width: 1100px;
  width: 100%;
}

.responsive-small-button {
  width: 25%;
}

.responsive-favorite-item-card {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.responsive-talent-detail {
  display: flex;
  align-items: center;
  justify-content: center;
}

.responsive-talent-card {
  display: flex;
  justify-content: space-between;
}

.w-0 {
  width: 0px;
}

.work-experience-styles {
  width: 500px;
  height: 300px;
  overflow: auto;
}

.custom-checkbox-two {
  width: 40px;
  border: 2px solid black;
  cursor: pointer;
}

.responsive-modal-two {
  z-index: 1000000;
}

.switch input {
  display: none;
}

.switch {
  display: inline-block;
  width: 48px;
  height: 24px;
  margin: 4px;
  transform: translateY(50%);
  position: relative;
}

.slider {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  border-radius: 30px;
  cursor: pointer;
  border: 4px solid transparent;
  overflow: hidden;
  transition: 0.2s;
  background-color: #bfd8cf;
}

.slider:before {
  position: absolute;
  content: '';
  width: 100%;
  height: 100%;
  background-color: #89b5a4;
  border-radius: 30px;
  transform: translateX(-22px); /*translateX(-(w-h))*/
  transition: 0.2s;
}

input:checked + .slider:before {
  transform: translateX(22px); /*translateX(w-h)*/
  background-color: #fafafa;
}

input:checked + .slider {
  background-color: #00cf83;
}

.switch200 .slider:before {
  width: 200%;
  transform: translateX(-82px); /*translateX(-(w-h))*/
}

.switch200 input:checked + .slider:before {
  background-color: red;
}

.switch200 input:checked + .slider {
  box-shadow: 0 0 0 2px red, 0 0 8px red;
}

.wrapper-styles {
  min-height: 95vh;
}

.chat-styles {
  right: 0;
  bottom: 0;
  margin-right: 25px;
  width: 15%;
}

.chat-open-styles {
  background: #3c3c3b;
  right: 0;
  bottom: 0;
  margin-right: 25px;
  width: 18%;
}

.chat-open-header-styles {
  height: 30px;
}

.open-one-chat {
  background: #3c3c3b;
  right: 0;
  bottom: 0;
  width: 18%;
  margin-right: 25%;
  z-index: 1000;
}

.inline-text {
  white-space: nowrap;
}

/* justify-content-between align-items-end */

.new-sidebar {
  left: 0px;
  width: 256px;
  min-width: 256px;
  height: 100vh;
  border-radius: 0px 8px 8px 0px;
}

.sidebar-animation {
  animation: moveToRight 0.7s ease-in-out;
}

.sidebar-button {
  // height: 40px;
  // width: 224px;
  // left: 0px;
  // top: 8px;
  border-radius: 24px;
  // padding: 0px 16px 0px 32px;
}

li > .sidebar-button {
  color: #e8ffff;
}

.sidebar-button:hover {
  // height: 40px;
  // width: 224px;
  // left: 0px;
  // top: 8px;
  border-radius: 24px;
  // padding: 0px 16px 0px 32px;
  background: #74a4a4;
  cursor: pointer;
  transition: 0.8s ease-out;
}

.sidebar-button-inside {
  margin-left: 30px;
}

.menu-container {
  width: 100%;
}

.menu-items-container {
  list-style: none;
  height: 300px;
  overflow-y: auto;
  overflow-x: hidden;
}

.divider {
  height: 1px;
  width: 220px;
  left: 0px;
  top: 0px;
  border-radius: 24px;
}

.inline-container {
  height: 56px;
  width: 8px;
}

.middle-line {
  height: 1px;
  width: 8px;
  left: 8px;
  top: 28px;
  border-radius: 24px;
}

.down-line {
  height: 1px;
  width: 28px;
  left: 1px;
  top: 27px;
  border-radius: 24px;
}

.up-line {
  height: 1px;
  width: 28px;
  left: 1px;
  top: 0px;
  border-radius: 24px;
}

.sidebar-footer {
  height: 129px;
  width: 224px;
  left: 15px;
  top: 598;
  border-radius: 0px;
}

.z-index-footer {
  position: relative;
  z-index: 100;
}

.log-out {
  color: #74a4a4;
}

.log-out:hover {
  color: #fafafa;
}

.dashboard-wrapper {
  margin-left: 80px;
  margin-right: 80px;
}

.user-info-text-primary {
  font-weight: 800;
}

.user-info-text-secondary {
  font-weight: 300;
  size: 24px;
  line-height: 30.12px;
}

.user-metrics-text-primary {
  width: 100%;
  font-weight: 700;
}

.line-container {
  width: 500px;
}

.single-line {
  white-space: pre;
}

.count-containter {
  height: 100%;
  width: 248px;
  left: 0px;
  top: 0px;
  border-radius: 16px;
  padding: 16px;
}

.count-text {
  font-size: 52px;
  font-weight: 900;
  line-height: 52px;
  letter-spacing: 0em;
}

.count-title {
  white-space: pre;
}

.count-master {
  width: 229px;
  height: 94px;
}

.count-custom {
  height: 94px;
}

.profile-container {
  position: absolute;
  z-index: 20;
  right: 0;
  margin-right: 80px;
  top: 40px;
  border-radius: 0px;
}

.profile-extra-container {
  position: fixed;
  height: 100vh;
  width: 100%;
  background: rgba(250, 250, 250, 0.7);
  backdrop-filter: blur(3.5px);
  top: 0;
  right: 0;
  z-index: 100;
}

.menu-burger {
  position: absolute;
  top: 5;
}

.dashboard-content-containter {
  width: 100%;
  border-radius: 0px;
}

.dashboard-content-text-primary {
  width: 100%;
  font-weight: 800;
}

.dashboard-content-text-secondary {
  width: 600px;
  font-weight: 400;
}

.drawer {
  position: fixed;
  top: 0;
  margin-left: -4px;
  z-index: 100000000;
  background: rgba(250, 250, 250, 0.7);
  backdrop-filter: blur(3.5px);
}

.wrapper-margin {
  margin-left: 163px;
  margin-right: 163px;
}

.wrapper-margin-list-of-talents {
  margin-left: 70px;
  margin-right: 70px;
}

.candidates-wrapper-margin {
  margin-left: 50px;
  margin-right: 50px;
}

.hero-wrapper-margin {
  margin-left: 163px;
  margin-right: 163px;
}

.benefits-hutrit-margin {
  margin-left: 150px;
  margin-right: 150px;
}

.wrapper-margin {
  margin-left: 163px;
  margin-right: 163px;
}

.why-hutrit-margin {
  margin-left: 163px;
  margin-right: 163px;
}

.my-resume-margin {
  margin-left: 163px;
  margin-right: 163px;
}

.fixed-margin-left {
  margin-left: 32px;
}

.fixed-margin-right {
  margin-right: 32px;
}

.irregular-icon {
  margin-top: -30px;
}

.start-first-text {
  z-index: 100;
}

.filters-container {
  min-height: 784px;
}

.filters-container-talents {
  min-height: 558px;
}

.filters-container-two {
  height: 750px;
  overflow: auto;
}

.filters-container-talents-two {
  height: 500px;
  overflow: auto;
}

.english-level {
  width: 300px;
  overflow-y: hidden;
  overflow-x: auto;
}

.white-block {
  width: 200px;
  height: 200px;
}

.benefits-hutrit {
  display: grid;
  grid-template-columns: 40% 47%;
}

.benefits-hutrit-container {
  padding-top: 50px;
  padding-bottom: 50px;
}

.benefits-hutrit-left-top {
  width: 424px;
  height: 270px;
  border-radius: 24px 24px 0px 0px;
}

.benefits-hutrit-left-bottom {
  width: 600px;
  height: 254px;
  border-radius: 0px 24px 24px 24px;
}

.benefits-hutrit-right-top {
  display: flex;
  justify-content: center;
  width: 600px;
  height: 254px;
  border-radius: 24px 24px 0px 24px;
}

.benefits-hutrit-right-bottom {
  width: 424px;
  height: 270px;
  border-radius: 0px 0px 24px 24px;
}

.why-hutrit-line {
  position: absolute;
  top: 38%;
  left: 3%;
}

.why-hutrit-two {
  position: absolute;
  top: -85%;
  bottom: 0;
  left: 9.2%;
}

.why-hutrit-second-point {
  margin-left: 17.5%;
}

.more-space {
  margin-top: 5% !important;
}

.years-of-experience {
  margin-right: 22.5px;
}

.drag__bar {
  appearance: none;
  width: 100%;
  height: 4px;
  border-radius: 5px;
  background: #a3c5c5;
  outline: none;
  opacity: 0.7;
  -webkit-transition: 0.2s;
  transition: opacity 0.2s;
}

.drag__bar::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 16px;
  height: 16px;
  border-radius: 50%;
  background: #fafafa;
  cursor: pointer;
  background-size: 45%;
  background-position: center;
  background-repeat: no-repeat;
  border: 4px solid $bg-CTA-primary;
}
s .drag__bar:hover {
  opacity: 1;
}

.mycheck {
  appearance: none;
}

.mycheck {
  cursor: pointer;
  background-color: #fff;
  background-image: url("data:image/svg+xml,%3csvg viewBox='0 0 16 16' fill='white' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M12.207 4.793a1 1 0 010 1.414l-5 5a1 1 0 01-1.414 0l-2-2a1 1 0 011.414-1.414L6.5 9.086l4.293-4.293a1 1 0 011.414 0z'/%3e%3c/svg%3e");
  height: 18px;
  width: 18px;
  border-radius: 2px;
  border: 1px solid $on-bg-primary;
  appearance: none;
  transition: background-color 0.3s ease-in-out;
}

.mycheck:checked {
  background-color: $bg-CTA-primary;
}

.mycheck:focus {
  border-color: $bg-CTA-primary;
}

.mycheck:disabled {
  background-color: rgb(198, 198, 198);
  background-image: none;
}

.mycheck:disabled:checked {
  background-color: rgb(198, 198, 198);
  background-image: url("data:image/svg+xml,%3csvg viewBox='0 0 16 16' fill='white' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M12.207 4.793a1 1 0 010 1.414l-5 5a1 1 0 01-1.414 0l-2-2a1 1 0 011.414-1.414L6.5 9.086l4.293-4.293a1 1 0 011.414 0z'/%3e%3c/svg%3e");
}

.mycheck {
  transition: background-color 0.3s ease-in-out;
}

.mycheck {
  appearance: none;
}

.mycheckTwo {
  appearance: none;
}

.custom-checkbox-span {
  display: inline-block;
  position: relative;
}

.custom-checkbox-span:after {
  content: '';
  position: absolute;
}

.mycheckTwo:checked + .custom-checkbox-span {
  background-color: $bg-CTA-primary-selected !important;
  color: $on-bg-CTA-primary-selected !important;
  border-color: none !important;
}

.start-today {
  margin-top: 100px;
  border-top-left-radius: 700px 400px;
  border-top-right-radius: 700px 400px;
}

.hutrit-login-icon {
  margin-top: 181px;
  margin-bottom: 48px;
}

.hutrit-register-icon {
  margin-top: 147px;
  margin-bottom: 48px;
}

.navigate-left-skills {
  display: block;
  height: 50px;
  overflow: hidden;
  position: relative;
  width: max-content;
  animation: navigate-left-skills 240s linear infinite;
}

.navigate-right-skills {
  display: block;
  height: 50px;
  overflow: hidden;
  position: relative;
  width: max-content;
  animation: navigate-right-skills 240s linear infinite;
}

.verification-email-message {
  min-height: 100vh;
}

.verification-email-message > div {
  box-shadow: 0px 2px 4px rgba(0, 33, 33, 0.075);
  border-radius: 24px;
}

.list-of-skills-two {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: repeat(3, 1fr);
  grid-gap: 20px;
  width: 100%;
}

.skill-item {
  height: 20px;
}

.skill-bar-percentage {
  position: absolute;
  right: 0;
  margin-right: 5px;
}

.line {
  flex-grow: 1;
}

.my-resume-date-text {
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
}

.work-experience-card {
  width: 100%;
  word-wrap: break-word;
}

.phone-landing-img {
  width: 60%;
  margin-left: auto;
  margin-right: auto;
  z-index: 10;
  margin-bottom: 25%;
  position: relative;
}

.laptop-landing-img {
  width: 50%;
  margin-left: auto;
  margin-right: auto;
  z-index: 10;
  margin-bottom: 25%;
  position: relative;
}

.green-arches-icon-phone {
  bottom: -45%;
}

.green-arches-icon-laptop {
  bottom: -45%;
  left: 20%;
}

.notification {
  width: 50%;
  background: rgba(0, 61, 61, 0.8);
  backdrop-filter: blur(2.5px);
  border-radius: 12px;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 12px 18px;
  gap: 10px;
  z-index: 100;
}

.notification-two {
  width: 50%;
  background: rgba(0, 61, 61, 0.8);
  backdrop-filter: blur(2.5px);
  border-radius: 12px;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 12px 18px;
  gap: 10px;
  margin-left: 50%;
  z-index: 100;
}

.list-of-notifications-phone {
  margin-top: 10%;
  margin-left: 30%;
}

.list-of-notifications-laptop {
  margin-top: 10%;
  margin-left: 20%;
}

.buttons-carousel-container-phone {
  left: 30%;
  bottom: -25%;
}

.buttons-carousel-container-laptop {
  left: 30%;
  bottom: -35%;
}

.phrase-container {
  bottom: -25px;
}

.carousel-button {
  width: 72px;
  height: 4px;
}

.list-of-skills {
  height: 250px;
  overflow: auto;
}

.custom-width {
  width: 120%;
}

.font-small {
  font-size: 14px;
}

.font-small-2 {
  font-size: 12px !important;
}

.font-small-3 {
  font-size: 10px !important;
}

.font-extra-small {
  font-size: 12px !important;
}

.font-very-small {
  font-size: 8px !important;
}

.font-very-small-two {
  font-size: 6px !important;
}

.custom-w {
  width: 25%;
}

.font-size-responsive {
  font-size: 16px;
}

.filter-mobile-background {
  height: 100vh;
  position: absolute;
  background: rgba(250, 250, 250, 0.7);
  backdrop-filter: blur(3.5px);
}

.navbar-drawer-container {
  position: fixed;
  width: 100%;
  height: 100vh;
  background: $bg-brand-pressed;
  z-index: 10000;
}

.my-resume-header {
  z-index: 100000;
}

.arrow-left-notifications-style {
  left: 0;
}

.history-skills {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(2, 1fr);
}

.history-container {
  display: flex;
  flex-direction: column;
  height: 300px;
  overflow: auto;
}

.history-list-of-items {
  width: 320px;
}

.list-of-talents {
  min-height: 100vh;
}

.sector-company > select {
  color: $on-bg-secondary;
}

.select-on-bg-secondary > select {
  color: $on-bg-secondary;
}

.letter {
  width: 210mm;
  height: auto;
  margin: 0 auto;
  padding: 20px;
  border: 1px solid #ccc;
  background-color: #fff;
  font-family: Arial, sans-serif;
  overflow: hidden;
  box-sizing: border-box;
}

.terms-and-conditions-header {
  text-align: center;
  margin-bottom: 10px;
}

.content {
  text-align: justify;
  text-justify: inter-word;
  line-height: 1.5;
}

.stepper-container {
  margin-top: 80px;
}

.form-step-two {
  width: 58%;
}

.end-step-container {
  padding: 42px 48px 42px 48px !important;
}

.description-text {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  width: 800px;
  overflow-wrap: break-word;
  overflow: hidden;
  text-overflow: ellipsis;
}

.company-card-container {
  width: 600px;
  height: 600px;
}

.talent-card-container {
  width: 600px;
  height: 600px;
}

.how-it-work-padding-top-container {
  padding-top: 20px;
}

.job-offer-download-content {
  width: 60%;
}

.job-offer-download-text {
  max-width: 90%;
  overflow-wrap: break-word;
}

.share-popover {
  top: -95px;
}

.skills-container {
  height: 362px;
  overflow: auto;
}

.drag-and-drop {
  border-radius: 8px;
  border: 1px dashed var(--bg-on-bg-primary, #004949);
  background: var(--bg-bg-tertiary, #e4f8ed);
}

.modal-custom-styles {
  background: rgba(250, 250, 250, 0.7) !important;
  backdrop-filter: blur(3.5px) !important;
}

.margin-top-custom {
  margin-top: 100px;
}

.mision-w {
  width: 100%;
  height: 502px;
}

.vision-w {
  width: 100%;
  height: 502px;
}

.all-notifications {
  z-index: 200;
  overflow: auto;
}

.font-xxl {
  font-size: 128px;
}

.min-heigth-100vh {
  min-height: 100vh;
}

.count-down-card {
  width: 688px;
  height: 470.92px;
  flex-shrink: 0;
}

.loader-containter {
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.bg-blur {
  background: rgba(250, 250, 250, 0.7);
  backdrop-filter: blur(3.5px);
  width: 100%;
  height: 100%;
  z-index: 100;
}

.requirements-container {
  width: 1050px;
}

.show-match-count-container {
  width: 325px;
  height: 108px;
}

.sent-job-offer-button {
  height: 108px;
}

.match-count {
  font-family: Mulish;
  font-size: 64px;
  font-style: normal;
  font-weight: 800;
  line-height: 62px;
}

.list-of-job-offers-modal {
  position: absolute;
  width: 60%;
  right: 0;
  z-index: 30;
}

.list-of-job-offers-send-modal {
  position: absolute;
  right: 0;
  z-index: 30;
  top: 45px;
}

.list-of-job-offers-button-container {
  box-shadow: 0px -2px 4px 0px rgba(0, 33, 33, 0.08);
}

.filter-modal-input {
  width: 60%;
}

.whatsapp-button {
  position: fixed;
  right: 0;
  bottom: 0;
  margin-right: 83px;
  margin-bottom: 32px;
  z-index: 300;
}

.bubble-warning-modal {
  position: fixed;
  right: 0;
  bottom: 0;
  margin-right: 100px;
  margin-bottom: 95px;
  z-index: 300;
}

.warning-bubble-icon {
  margin-top: -50px;
}

.custom-table {
  max-width: 1100px;
}

.extra-z-index {
  position: absolute;
  z-index: 10000;
}

.tutorial-step-two {
  right: 0;
}

.tutorial-step-fourth-popup {
  margin-left: 228px;
  margin-top: 55.5px;
}

.tutorial-step-fourth-column {
  margin-left: 175px;
}

.tutorial-step-fifth-popup {
  margin-left: 299px;
}

.tutorial-step-fifth-column-two {
  margin-left: 550px;
}

.register-button-mobile {
  z-index: 10000;
}

.hoops-icon {
  position: absolute;
  bottom: 0;
  z-index: 0;
}

.hoops-landing-icon {
  position: absolute;
  z-index: 0;
}

.hero-container {
  min-height: 100vh;
}

.hero-card {
  height: 550px;
}

.hero-card-title {
  font-size: 72px;
  font-weight: 900;
  line-height: 77.04px;
}

.hero-title-two {
  font-size: 52px;
  line-height: 64px;
}

.hero-subtitle-one {
  font-size: 20px;
  line-height: 25.1px;
  font-weight: 400;
}

.hero-subtitle-two {
  font-size: 24px;
}

.hero-browse-image-container {
  padding-top: 150px;
}

.laptop-icon {
  z-index: 100;
}

.rectangle-icon {
  position: absolute;
  bottom: 0;
  z-index: 0;
}

.list-of-process-container {
  width: 272px;
  height: 83.5vh;
}

.process-job-offer-card:hover {
  background-color: #e4f8ed;
  opacity: 0.9;
}

*::-webkit-scrollbar {
  -webkit-appearance: none;
}

*::-webkit-scrollbar:vertical {
  width: 6px;
  height: 6px;
}

*::-webkit-scrollbar-button:increment,
*::-webkit-scrollbar-button {
  display: none;
}

*::-webkit-scrollbar:horizontal {
  width: 6px;
  height: 6px;
}

*::-webkit-scrollbar-thumb {
  background-color: #89d3b8;
  border-radius: 4px;
}

*::-webkit-scrollbar-track {
  border-radius: 4px;
}

.chat-message-icon {
  right: 0;
  bottom: 0;
  margin-right: 80px;
  margin-bottom: 48px;
}
.process-tutorial-step-one {
  margin-left: 540px;
}

.process-tutorial-step-two {
  margin-right: 960px;
}

.robot-container {
  margin-top: 25px;
  line-height: 19px;
}

.chat-container {
  right: 0;
  bottom: 0;
  margin-right: 80px;
  margin-bottom: 38px;
  width: 336px;
  height: 593px;
  border-radius: 20px;
  box-shadow: 0px 16px 48px 0px rgba(43, 17, 89, 0.17);
  z-index: 200;
}

.chat-header {
  height: 64px;
  border-top-right-radius: 20px;
  border-top-left-radius: 20px;
}

.chat-header > div {
  margin-right: 12px;
}

.chat-messenger-service-space {
  margin-right: 95px;
  font-size: 14px;
}

.chat-empty-state-container {
  margin-top: 68px;
}

.chat-empty-state-text-one {
  margin-bottom: 35px;
}

.chat-empty-state-text-two {
  margin-top: 42px;
}

.list-of-chats-container {
  height: 500px;
}

.font-normal {
  font-size: 14px;
}

.single-chat-container:hover {
  background-color: $on-bg-Tertiary-hover;
}

.robot-icon {
  top: -56px;
}

.chatgpt-assistant {
  max-width: 1050px;
}

.chatgpt-assistant-card {
  padding-left: 32px;
  padding-right: 32px;
  padding-top: 24px;
  padding-bottom: 24px;
}

.disable-button-styles {
  background: none;
  border: 0;
  color: inherit;
  /* cursor: default; */
  font: inherit;
  line-height: normal;
  overflow: visible;
  padding: 0;
}

.offline-icon {
  bottom: 0;
  right: 0;
  top: 14px;
}

.support-content {
  display: none;
}

.support-button:hover .support-content {
  display: flex;
  flex-direction: column;
  gap: 30px;
}

.support-button-container {
  z-index: 30;
}

.quick-offer-card {
  width: 368px;
  height: 260px;
}

.quick-offer-section-four {
  background-image: url('../src/assets/quick_offer_section_four.png');
  background-size: cover;
  border-radius: 1rem;
}

.ranking-tm-detail-drawer {
  width: 100vh;
  height: 100vh;
  top: 0;
  z-index: 100;
}

.tiptap {
  margin-top: 5px;
  padding: 3px;
  height: 80px;
  overflow: auto;
}

.tiptap:hover {
  cursor: text;
}

.tiptap:focus {
  outline: none;
}

.dropdown-toggle::after {
  display: none !important;
}

.about-me-talent > div {
  width: 500px;
  min-height: 135px;
  word-wrap: break-word;
}

.percentage-skill {
  width: 5px;
  height: 10px;
  border-radius: 2px;
  margin-right: 5px;
}

.chat-badge {
  margin-top: 6px;
  margin-left: 55px;
}

.tooltip-container {
  position: relative;
  display: inline-block;
}

.tooltip-custom {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  background-color: $bg-CTA-secondary;
  color: $on-bg-primary;
  padding: 12px;
  border-radius: 8px;
  font-size: 14px;
  word-wrap: break-word;
}

.tooltip-custom-two {
  position: absolute;
  background-color: $bg-CTA-secondary;
  color: $on-bg-primary;
  padding: 12px;
  border-radius: 8px;
  font-size: 14px;
  word-wrap: break-word;
  z-index: 12000000;
}

.tooltip-custom-top {
  bottom: 125%;
  left: 50%;
  transform: translateX(-50%);
}

.tooltip-custom-right {
  top: 50%;
  left: 125%;
  transform: translateY(-50%);
}

.tooltip-custom-bottom {
  top: 125%;
  left: 50%;
  transform: translateX(-50%);
}

.tooltip-custom-left {
  top: 50%;
  right: 125%;
  transform: translateY(-50%);
}

.talent-asociate-tm-container {
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 16px;
  margin-bottom: 10px;
  margin-top: 16px;
}

.tm-talent-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
}

.object-fit-cover {
  object-fit: cover;
}

.country-filter-collapse {
  width: 240px !important;
  height: 272px !important;
  overflow: auto !important;
}

.date-alert-modal {
  background: linear-gradient(57.13deg, #ffffff 0.58%, #e4ecff 100%) !important;
}

.feedback-modal {
  background: linear-gradient(57.13deg, #ffffff 0.58%, #e4ecff 100%) !important;
}

.super-search-modal {
  position: fixed;
  backdrop-filter: blur(3.5px);
  background: hsla(0, 0%, 98%, 0.7);
  width: 100%;
  height: 100vh;
  z-index: 500;
}

.super-search-modal-container {
  width: 460px;
}

.super-search-category-input {
  width: 450px;
}

.super-search-skills-input {
  width: 300px;
}

.super-search-container {
  border: double 4px transparent;
  border-radius: 16px;
  background-image: linear-gradient(white, white),
    linear-gradient(270deg, #00cf83 0%, #2498d9 46%, #00cf83 100%);
  background-origin: border-box;
  background-clip: content-box, border-box;
}

.animation-bounce {
  animation: bounce 1.6s infinite;
}

.custom-bg {
  background: linear-gradient(100deg, #00cf83 40%, #00ffa1 100%);
  background-clip: text !important;
  color: transparent;
}

.skill {
  display: inline-block;
  margin: 20px;
}

.circle {
  width: 32px;
  height: 32px;
  border-radius: 50%;
  background-color: #cff2df;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 10px;
  color: #004949;
}

.bar-container {
  width: 100px;
}

.bar {
  width: 10px;
  height: 5px;
  display: inline-block;
  margin-right: 2px;
  border-radius: 12px;
}

.who-is-hutrit-for-container {
  gap: 300px;
}

.who-is-hutrit-item {
  gap: 0px;
}

.why-hutrit-container {
  margin-top: 160px;
}

.irregular-icon-container {
  top: 38px;
}

.change-tm-selected:hover {
  opacity: 50%;
}

.traffic-light-item {
  width: 58px;
  height: 8px;
  border-radius: 12px;
}

.traffic-light-item-secondary {
  width: 100%;
  height: 8px;
  border-radius: 12px;
}

.select-item {
  cursor: pointer;
}

.select-item:hover {
  opacity: 50%;
}

.talent-information-container {
  height: 100vh !important;
  overflow-y: 'auto' !important;
  overflow-x: 'hidden' !important;
}

.hide-scrollbar::-webkit-scrollbar {
  display: none;
}

.list-of-talents-container {
  display: grid;
  grid-template-columns: 50% 50%;
  gap: 16px;
}

.hover-job-offer-card:hover {
  border: 1px solid #00cf83 !important;
}

.list-of-job-offers-company {
  display: grid;
  grid-template-columns: 50% 50%;
  gap: 16px;
}

/* HeroSection.css */
.hero-container-v2 {
  position: relative;
  width: 100%;
  overflow: hidden;
  opacity: 95%;
  background: #001f1f;
  z-index: 2;
}

.video-overlay {
  position: relative;
  width: 100%;
  height: 100%;
}

.hero-video {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 100%;
  height: 100%;
  object-fit: cover;
  transform: translate(-50%, -50%);
  z-index: -1;
  filter: hue-rotate(283deg) brightness(0.4);
  opacity: 15%;
}

.hero-content {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  color: white;
  z-index: 3;
  width: 100%;
}

.overlay-left,
.overlay-right {
  position: absolute;
  top: 0;
  bottom: 0;
  width: 40%;
  background: linear-gradient(to left, transparent, black);
  z-index: 0;
}

.overlay-left {
  left: 0;
}

.overlay-right {
  right: 0;
  background: linear-gradient(to right, transparent, black);
}

.carousel-container {
  width: 100%;
  overflow: hidden;
}

.steps {
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
}

.step {
  padding: 10px 20px;
  cursor: pointer;
  transition: color 0.3s;
}

.step.active {
  font-weight: bold;
  color: blue;
}

.carousel-section-two {
  display: flex;
  scroll-behavior: smooth;
  overflow-x: hidden;
}

.carousel-item {
  min-width: 100%;
  flex: 1 0 100%;
  text-align: center;
}

.carousel-item img {
  width: 100%;
  max-height: 400px;
  object-fit: cover;
}

.company-form-modal-landing-container {
  width: 630px;
}

.benefits-card {
  position: relative;
  overflow: hidden;
  transition: all 1s ease-in-out; /* Transición para el contenedor principal */

  .more-text {
    opacity: 0;
    max-height: 0;
    overflow: hidden;
    transition: opacity 1s ease-in-out, max-height 1s ease-in-out; /* Transición para el texto adicional */
  }

  &.show-more .more-text {
    opacity: 1;
    max-height: 500px; /* Aumenta este valor si el contenido es más largo */
  }

  &.show-less .more-text {
    opacity: 0;
    max-height: 0;
  }
}

.custom-transition {
  transition: all 0.5s ease-in-out;
}

.hero-section {
  overflow: hidden;
  position: relative;
  z-index: 1; /* Asegura que esta sección esté debajo de las siguientes cuando se desplaza */
}

.hero-image,
.hero-image-mobile {
  z-index: 0;
  transition: transform 0.5s ease-in-out; /* Transición para un efecto suave */
}
.hero-section:hover .hero-image,
.hero-section:hover .hero-image-mobile {
  transform: translateY(50px); /* Efecto de desplazamiento hacia abajo */
}

.progress-bar-container {
  width: 100%;
  background-color: #cff2df;
  border-radius: 8px;
  overflow: hidden;
}

.progress-bar-subscription {
  height: 8px;
  background-color: #00ce83;
  border-radius: 8px 0 0 8px;
  transition: width 0.3s ease-in-out;
}

.searchContainer {
  border-radius: 16px;
  background: #fff;
}

@media (max-width: 1400px) {
  .custom-table {
    max-width: 600px;
  }

  .requirements-container {
    width: 800px;
  }

  .why-hutrit-two {
    position: absolute;
    top: -85%;
    bottom: 0;
    left: 7%;
  }

  .my-resume-container {
    max-width: 900px;
    width: 100%;
  }
}

@media (max-width: 1300px) {
  .requirements-container {
    width: 100%;
  }

  .custom-w {
    width: 50%;
  }

  .years-of-experience {
    margin-right: 12px;
  }

  .benefits-hutrit {
    display: grid;
    grid-template-columns: 50% 55%;
  }

  .hero-wrapper-margin {
    margin-left: 100px;
    margin-right: 163px;
  }

  .benefits-hutrit-margin {
    margin-left: 100px;
    margin-right: 100px;
  }

  .my-resume-margin {
    margin-left: 100px;
    margin-right: 100px;
  }

  .more-space {
    margin-top: 5% !important;
  }

  .why-hutrit-margin {
    margin-left: 50px;
    margin-right: 50px;
  }

  .why-hutrit-two {
    position: absolute;
    top: -85%;
    bottom: 0;
    left: 8%;
  }

  .my-resume-container {
    max-width: 600px;
    width: 100%;
  }
}

@media (min-width: 1300px) {
  .height-all-viewport {
    height: 100vh;
  }
}

@media (max-width: 992px) {
  .hero-content {
    position: absolute;
    top: 35%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    color: white;
    z-index: 1; /* Asegura que el contenido esté por encima del video */
    width: 100%;
    padding: 10px;
  }

  .about-me-talent > div {
    width: 300px;
    min-height: 135px;
    word-wrap: break-word;
  }

  .quick-offer-card {
    width: 312px;
    height: 260px;
  }

  .bubble-warning-modal {
    position: fixed;
    right: 0;
    bottom: 0;
    z-index: 300;
    margin: 18px;
  }

  .chat-message-icon {
    right: 0;
    bottom: 0;
    margin-right: 5px;
    margin-bottom: 5px;
  }

  .list-of-chats-container {
    height: 100vh;
  }

  .chat-messenger-service-space {
    margin-right: 114px;
    font-size: 14px;
  }

  .chat-empty-state-container {
    margin-top: 177px;
  }

  .chat-header {
    height: 64px;
    border-top-right-radius: 0px;
    border-top-left-radius: 0px;
  }

  .chat-container {
    right: 0;
    bottom: 0;
    margin-right: 0px;
    margin-bottom: 0px;
    width: 100%;
    height: 100%;
    border-radius: 0px;
  }

  .hero-container {
    min-height: 20vh;
  }

  .whatsapp-button {
    margin-right: 19px;
    margin-bottom: 88px;
  }

  .count-down-card {
    width: 50%;
    height: auto;
    flex-shrink: 0;
  }

  .all-notifications {
    z-index: 200;
    height: auto;
    overflow: auto;
  }

  .my-resume-container {
    max-width: 100%;
    width: 100%;
  }

  .company-card-container {
    width: 100%;
    height: 100%;
  }

  .talent-card-container {
    width: 100%;
    height: 100%;
  }

  .wrapper-margin-list-of-talents {
    margin-left: 0px;
    margin-right: 0px;
  }

  .irregular-icon {
    margin-top: -25px;
  }

  .list-of-notifications-phone {
    margin-top: 0;
    margin-left: 10%;
  }

  .list-of-notifications-laptop {
    margin-top: -8%;
    margin-left: 0;
  }

  .notification-styles {
    right: 0;
    top: 0;
    margin-top: 0px;
    margin-right: 0px;
    width: 100%;
    height: 100vh;
    z-index: 100;
    overflow: auto;
  }

  .list-of-skills-two {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(2, 1fr);
    grid-gap: 20px;
    width: 90%;
  }

  .my-resume-margin {
    margin-left: 0px;
    margin-right: 0px;
  }

  .wrapper-margin {
    margin-left: 0px;
    margin-right: 0px;
  }

  .font-small-benefits {
    font-size: 12px;
  }

  .font-size-responsive {
    font-size: 14px;
    line-height: 18px;
  }

  .phone-landing-img {
    width: 100%;
    margin-left: -5px;
    margin-right: 0px;
    z-index: 10;
    margin-bottom: 25%;
    position: relative;
  }

  .laptop-landing-img {
    width: 100%;
    margin-left: -5px;
    margin-right: 0px;
    z-index: 10;
    margin-bottom: 25%;
    position: relative;
  }

  .notification {
    width: 70%;
    font-size: 10px;
    background: rgba(0, 61, 61, 0.8);
    backdrop-filter: blur(2.5px);
    border-radius: 12px;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 12px 18px;
    gap: 10px;
    z-index: 100;
  }

  .custom-height {
    min-height: 100vh;
  }

  .notification-two {
    width: 70%;
    font-size: 10px;
    background: rgba(0, 61, 61, 0.8);
    backdrop-filter: blur(2.5px);
    border-radius: 12px;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 12px 18px;
    gap: 10px;
    margin-left: 28%;
    z-index: 100;
  }

  .start-today {
    margin-top: 30px;
    border-top-left-radius: 0 0;
    border-top-right-radius: 0 0;
  }

  .candidates-wrapper-margin {
    margin-left: 0px;
    margin-right: 0px;
  }

  .more-space {
    margin-top: 0px !important;
  }

  .hero-wrapper-margin {
    margin-left: 10px;
    margin-right: 10px;
  }

  .why-hutrit-margin {
    margin-left: 10px;
    margin-right: 10px;
  }

  .fixed-margin-left {
    margin-left: 0;
  }

  .dashboard-wrapper {
    margin-left: auto;
    margin-right: auto;
    width: 100%;
  }

  .user-info {
    width: 100%;
  }

  .metrics-counts {
    overflow: auto;
  }

  .dashboard-content-text-secondary {
    width: 100%;
    font-weight: 400;
  }

  .profile-container {
    position: absolute;
    z-index: 20;
    right: 0;
    margin-right: 36px;
    top: 40px;
    border-radius: 0px;
  }

  .dashboard-content-containter {
    width: 100%;
    border-radius: 0px;
    margin-top: px;
  }

  .dashboard-container {
    margin-left: 0px;
    margin-top: 84px;
  }

  .user-info-text-primary {
    font-weight: 700;
  }

  .line-container {
    width: 100%;
  }

  .user-info-text-secondary {
    font-weight: 300;
    opacity: 0.5;
    size: 16px;
    line-height: 30.12px;
  }

  .menu-burger {
    display: flex;
    position: absolute;
    left: 0;
    margin-left: 20px;
    top: 40px;
    border-radius: 0px;
  }

  .super-search-modal-container {
    width: 100%;
  }

  .super-search-modal {
    padding: 16px;
    position: fixed;
    backdrop-filter: blur(3.5px);
    background: hsla(0, 0%, 98%, 0.7);
    width: 100%;
    height: 100vh;
    z-index: 1200;
  }

  .hero-card {
    height: 100%;
  }

  .hero-card-title {
    font-size: 38px;
    line-height: 47px;
  }

  .hero-subtitle-one {
    font-size: 16px;
  }

  .super-search-category-input {
    width: 100%;
  }

  .super-search-skills-input {
    width: 100%;
  }

  .super-search-field {
    width: 100%;
  }

  .hero-browse-image-container {
    padding-top: 57px;
  }

  .hero-title-two {
    font-size: 30px;
    line-height: 37px;
  }

  .hero-second-section {
    margin-top: 57px;
  }

  .irregular-icon-container {
    top: 24px;
    right: 93px;
  }

  .who-is-hutrit-for-container {
    gap: 20px;
  }

  .who-is-hutrit-item {
    gap: 24px;
  }

  .why-hutrit-container {
    margin-top: 133px;
  }

  .super-search-talent-detail-mobile {
    height: 90vh;
    background: $skeleton-color;
  }
}

@media (min-width: 1800px) {
  .why-hutrit-two {
    position: absolute;
    top: -85%;
    bottom: 0;
    left: 13.5%;
  }
}

@media (min-width: 1600px) {
  .why-hutrit-two {
    position: absolute;
    top: -85%;
    bottom: 0;
    left: 11.5%;
  }

  .why-hutrit-line {
    position: absolute;
    top: 45%;
    left: 3.2%;
  }
}

@media (min-width: 2500px) {
  .english-level {
    width: 500px;
    overflow-y: hidden;
    overflow-x: auto;
  }
}

@keyframes moveToRight {
  0% {
    transform: translateX(-256px);
  }
  100% {
    transform: translateX(0px);
  }
}

@keyframes navigate-left-skills {
  0% {
    left: 0;
  }
  100% {
    left: -100%;
  }
}

@keyframes navigate-right-skills {
  0% {
    left: -100%;
  }
  100% {
    left: 0%;
  }
}

// Animations

.blur-out {
  animation: blur-out 0.4s linear both;
}
@keyframes blur-out {
  0% {
    filter: blur(0.01px);
  }
  100% {
    filter: blur(12px);
    opacity: 0;
  }
}

@keyframes bounce {
  0%,
  20%,
  50%,
  80%,
  100% {
    transform: translateY(0);
  }
  40% {
    transform: translateY(-30px);
  }
  60% {
    transform: translateY(-15px);
  }
}
