// theme colors

$bg-primary: #fafafa;
$bg-secondary: #ffffff;
$on-bg-primary: #004949;
$on-bg-secondary: #6b8282;
$bg-brand: #004949;
$on-bg-brand: #e4f8ed;
$on-bg-brand-secondary: #74a4a4;
$bg-brand-hover: #74a4a4;
$bg-brand-pressed: #023c3c;
$on-bg-brand-pressed: #a3c5c5;
$bg-brand-selected: #2e6d6d;
$on-bg-brand-disabled: #336969;
$bg-CTA-primary: #00cf83;
$bg-CTA-primary-hover: #0be494;
$bg-CTA-primary-pressed: #0be494;
$bg-CTA-primary-pressed-two: #17af78;
$bg-CTA-primary-disabled: #bfd8cf;
$bg-CTA-primary-selected: #c0f5e2;
$on-bg-CTA-primary-disabled: #89b5a4;
$bg-CTA-secondary: #89d3b8;
$on-bg-CTA-secondary: #446659;
$bg-CTA-secondary-hover: #9bedcf;
$bg-CTA-secondary-pressed: #77b69f;
$bg-CTA-secondary-disabled: #c7dcd4;
$on-bg-CTA-secondary-disabled: #8eada2;
$illustration: #01a76a;
$on-illustration: #cff2df;
$on-bg-CTA-primary-selected: #208587;
$on-bg-Success: #157a08;
$on-bg-Tertiary: #b5c9c9;
$bg-Success: #d8f1d4;
$bg-tertiary: #e4f8ed;
$bg-danger: #ffd9d4;
$on-bg-danger: #ea3d29;
$danger-pressed: #ff7565;
$bg-success: #d8f1d4;
$on-bg-success: #157a08;
$bg-warning: #ffeeb2;
$on-bg-warning: #6b4426;
$bg-CTA-complementary-hover: #c0f5e2;
$bg-CTA-complementary-pressed: #a3dbc6;
$bg-CTA-complementary-disabled: #8e9f9d;
$grey: #9e9e9e;
$on-bg-Tertiary-pressed: #ace5c6;
$on-bg-Tertiary-hover: #cff2df;
$warning-100: #fef0c7;
$disable-secondary: #ededed;
$cta-primary-disabled: #89b5a4;
$bg-black: #001c1c;
$bg-pasive-gold: #ffe79b;
$bg-strong-gold: #cd7f40;
$bg-pasive-silver: #d5dcdf;
$bg-strong-silver: #86929a;
$bg-pasive-bronze: #edc3a2;
$bg-strong-bronze: #976d4b;
$neutral-color: #6d758f;
$skeleton-color: #f5f5f5;
$bg-success-pressed: #f7fdfb;
$bg-blue: #407bff;
$bg-blue-opacity: #f1f4fa;
$neutral-shadow: #e1e4ed;
$bg-yellow: #f7e00c;
$yellow-opacity: #f9f5ca;
$bg-green: #157a08;
$green-opacity: #cce0ca;
$red-opacity: #f7d4d0;
$old-rose: #ff5a70;
$bg-blue-pale: #eaf1ff;
$bg-on-primary-two: #001f1f;
$bg-blue-secondary: #ecf2ff;

$accordion-button-active-bg: white;
$accordion-button-focus-box-shadow: white;
$dropdown-link-active-bg: $on-bg-primary;

$theme-colors: (
  'bg-primary': $bg-primary,
  'bg-secondary': $bg-secondary,
  'on-bg-primary': $on-bg-primary,
  'on-bg-secondary': $on-bg-secondary,
  'bg-brand': $bg-brand,
  'on-bg-brand': $on-bg-brand,
  'on-bg-brand-secondary': $on-bg-brand-secondary,
  'bg-brand-hover': $bg-brand-hover,
  'bg-brand-pressed': $bg-brand-pressed,
  'on-bg-brand-pressed': $on-bg-brand-pressed,
  'bg-brand-selected': $bg-brand-selected,
  'on-bg-brand-disabled': $on-bg-brand-disabled,
  'bg-CTA-primary': $bg-CTA-primary,
  'bg-CTA-primary-hover': $bg-CTA-primary-hover,
  'bg-CTA-primary-pressed': $bg-CTA-primary-pressed,
  'bg-CTA-primary-pressed-two': $bg-CTA-primary-pressed-two,
  'bg-CTA-primary-disabled': $bg-CTA-primary-disabled,
  'on-bg-CTA-primary-disabled': $on-bg-CTA-primary-disabled,
  'bg-CTA-secondary': $bg-CTA-secondary,
  'on-bg-CTA-secondary': $on-bg-CTA-secondary,
  'bg-CTA-secondary-hover': $bg-CTA-secondary-hover,
  'bg-CTA-secondary-pressed': $bg-CTA-secondary-pressed,
  'bg-CTA-secondary-disabled': $bg-CTA-secondary-disabled,
  'bg-CTA-primary-selected': $bg-CTA-primary-selected,
  'bg-Success': $bg-Success,
  'on-bg-CTA-secondary-disabled': $on-bg-CTA-secondary-disabled,
  'on-bg-CTA-primary-selected': $on-bg-CTA-primary-selected,
  'on-bg-Success': $on-bg-Success,
  'on-bg-Tertiary': $on-bg-Tertiary,
  'bg-tertiary': $bg-tertiary,
  'illustration': $illustration,
  'on-illustration': $on-illustration,
  'white': $bg-primary,
  'bg-danger': $bg-danger,
  'on-bg-danger': $on-bg-danger,
  'danger-pressed': $danger-pressed,
  'bg-success': $bg-success,
  'on-bg-success': $on-bg-success,
  'bg-warning': $bg-warning,
  'on-bg-warning': $on-bg-warning,
  'bg-CTA-complementary-hover': $bg-CTA-complementary-hover,
  'bg-CTA-complementary-pressed': $bg-CTA-complementary-pressed,
  'bg-CTA-complementary-disabled': $bg-CTA-complementary-disabled,
  'grey': $grey,
  'on-bg-Tertiary-pressed': $on-bg-Tertiary-pressed,
  'on-bg-Tertiary-hover': $on-bg-Tertiary-hover,
  'warning-100': $warning-100,
  'disable-secondary': $disable-secondary,
  'cta-primary-disabled': $cta-primary-disabled,
  'bg-black': $bg-black,
  'bg-pasive-gold': $bg-pasive-gold,
  'bg-strong-gold': $bg-strong-gold,
  'bg-pasive-silver': $bg-pasive-silver,
  'bg-strong-silver': $bg-strong-silver,
  'bg-pasive-bronze': $bg-pasive-bronze,
  'bg-strong-bronze': $bg-strong-bronze,
  'neutral-color': $neutral-color,
  'skeleton-color': $skeleton-color,
  'bg-success-pressed': $bg-success-pressed,
  'bg-blue': $bg-blue,
  'bg-blue-opacity': $bg-blue-opacity,
  'neutral-shadow': $neutral-shadow,
  'bg-yellow': $bg-yellow,
  'yellow-opacity': $yellow-opacity,
  'bg-green': $bg-green,
  'green-opacity': $green-opacity,
  'red-opacity': $red-opacity,
  'old-rose': $old-rose,
  'bg-blue-pale': $bg-blue-pale,
  'bg-on-primary-two': $bg-on-primary-two,
  'bg-blue-secondary': $bg-blue-secondary,
);

$font-family-sans-serif: 'Mulish', sans-serif;
$modal-xl: 630px;
$modal-sm: 1100px;
$btn-close-width: 0.8rem;

@import '../node_modules/bootstrap/scss/bootstrap';
@import url('https://fonts.googleapis.com/css2?family=Mulish:wght@400;500;600;700;800;900;1000&display=swap');
@import '../node_modules/bootstrap/scss/variables';
@import '../node_modules/bootstrap/scss/mixins';
@import '../node_modules/bootstrap/scss/root';

// const renderBackground = {
//   1: 'bg-pasive-gold',
//   2: 'bg-pasive-silver',
//   3: 'bg-pasive-bronze',
// }

// const renderTextColor = {
//   1: 'bg-strong-gold',
//   2: 'bg-strong-silver',
//   3: 'bg-strong-bronze',
// }
